export default {
  data() {
    return {
      center: {
        lng: 114.35829,
        lat: 23.04901
      },
      show: true,
      contents: '惠州市惠城区广东惠州大学生创业孵化基地',
      isTargetElementVisible: false,
      //公司介绍动画
      isTargetElementVisible2: false //地图动画
    };
  },
  methods: {
    infoWindowClose(e) {
      this.show = false;
    },
    infoWindowOpen(e) {
      this.show = true;
    },
    clear() {
      this.contents = '';
    }
  },
  mounted() {
    const targetElement = this.$refs.targetElement;
    const targetElement2 = this.$refs.targetElement2;

    // 创建 Intersection Observer 实例
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isTargetElementVisible = true;
          console.log('进入了');
        } else {
          // this.isTargetElementVisible = false
          console.log('离开了');
        }
      });
    });
    const observer2 = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isTargetElementVisible2 = true;
          console.log('进入了');
        } else {
          // this.isTargetElementVisible = false
          console.log('离开了');
        }
      });
    });
    observer.observe(targetElement);
    observer2.observe(targetElement2);
  }
};