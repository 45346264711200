var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "aboutRoot"
  }, [_c('TopNav'), _c('div', {
    staticClass: "main"
  }, [_vm._m(0), _c('div', {
    staticClass: "midIntroduce"
  }, [_c('div', {
    staticClass: "introduceWrap"
  }, [_vm._m(1), _c('div', {
    ref: "targetElement",
    staticClass: "bottomAbout",
    class: _vm.isTargetElementVisible ? 'animate__animated animate__fadeInRight' : ''
  }, [_c('div', {
    staticClass: "leftImg"
  }), _vm._m(2)])])]), _c('div', {
    staticClass: "contactUs"
  }, [_c('div', {
    staticClass: "contactUsWrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("联系我们")]), _vm._m(3), _c('div', {
    ref: "targetElement2",
    staticClass: "map",
    class: _vm.isTargetElementVisible2 ? 'animate__animated animate__fadeInRight' : ''
  }, [_c('baidu-map', {
    staticClass: "map2",
    attrs: {
      "center": _vm.center,
      "zoom": 20,
      "dragging": true
    }
  }, [_c('bm-info-window', {
    attrs: {
      "position": {
        lng: 114.35829,
        lat: 23.04901
      },
      "title": "公司所在地",
      "show": true
    }
  }, [_c('p', {
    domProps: {
      "textContent": _vm._s('惠州市惠城区广东惠州大学生创业孵化基地')
    }
  })]), _c('bm-marker', {
    attrs: {
      "position": _vm.center,
      "dragging": true,
      "animation": "BMAP_ANIMATION_BOUNCE"
    }
  })], 1)], 1), _vm._m(4)])])]), _c('BottomNav')], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "headWrap"
  }, [_c('div', {
    staticClass: "titleWrap"
  }, [_c('div', {
    staticClass: "mainTitle"
  }, [_vm._v("凡百科技致力于打造轻松高效的办公体验")]), _c('div', {
    staticClass: "miniTitle"
  }, [_vm._v("提高用户办公效率，让工作更轻松！")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "topAbout animate__animated animate__fadeInLeft"
  }, [_c('div', {
    staticClass: "leftMsg"
  }, [_c('div', {
    staticClass: "mainTitle"
  }, [_vm._v("关于易兴软件")]), _c('div', {
    staticClass: "miniTitle"
  }, [_vm._v(" 易兴软件包含一系列办公软件，旨在帮助用户快速轻松地处理所有 事务，在提高生产力的同时帮助用户节省时间和精力，让用户有更 多的时间回归生活、享受生活。"), _c('br'), _vm._v(" 易兴数据恢复软件是我们团队的第一个软件，更多的软件即将推出， 敬请期待！ ")])]), _c('div', {
    staticClass: "rightImg"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "rightMsg"
  }, [_c('div', {
    staticClass: "mainTitle"
  }, [_vm._v("关于凡百科技")]), _c('div', {
    staticClass: "miniTitle"
  }, [_vm._v(" 凡百科技取名自成语：凡百一新，意味着所有的事物都有了新 气象。我们的团队拥有多年的软件开发经验，对个人和组织在 办公领域面临的挑战有着深刻的理解。我们希望通过我们团队 的努力，可以使用户在工作中得到高效愉快的新体验。 ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "links"
  }, [_c('div', {
    staticClass: "businessCooperation"
  }, [_vm._v(" 业务合作："), _c('span', [_vm._v("bd@yixingsoft.com")])]), _c('div', {
    staticClass: "contact"
  }, [_vm._v(" 联系我们："), _c('span', [_vm._v("support@yixingsoft.com")])]), _c('div', {
    staticClass: "vita"
  }, [_vm._v("简历投递："), _c('span', [_vm._v("hr@yixingsoft.com")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tips"
  }, [_c('i', {
    staticClass: "el-icon-location-outline"
  }), _c('span', [_vm._v("公司地址：广东省惠州市惠城区广东惠州大学生创业孵化基地")])]);
}];
export { render, staticRenderFns };